import { formatStrParams, makeRequest } from './config'
import {
  IGetCityWarehouseRequest,
  IAddCityWarehouseRequest,
  ICityWarehouseItem,
  IGetCityWarehouseDetailRequest,
  IUpdateCityWarehouseRequest
} from './apiModel/cityWarehouse'
import { IResponseDataModel } from './apiModel/publicApi'

const getCityWarehouse = (query: IGetCityWarehouseRequest) =>
  makeRequest<IResponseDataModel<ICityWarehouseItem[]>>(`cityWarehouse?${formatStrParams(query)}`, { method: 'GET' })

const getCityWarehouseDetailInfo = ({ NAV_Code }: IGetCityWarehouseDetailRequest) =>
  makeRequest<IResponseDataModel<ICityWarehouseItem>>(`cityWarehouse/${NAV_Code}`, { method: 'GET' })

const updateCityWarehouseDetailInfo = (query: IUpdateCityWarehouseRequest) => {
  const NAV_Code = query.OldNavCode || query.NAV_Code;
  delete query.OldNavCode
  return makeRequest(`cityWarehouse/${NAV_Code}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query)
  })
}

const createCityWarehouseDetailInfo = (query: IAddCityWarehouseRequest) =>
  makeRequest('cityWarehouse', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  })

export default {
  getCityWarehouse,
  getCityWarehouseDetailInfo,
  updateCityWarehouseDetailInfo,
  createCityWarehouseDetailInfo,
}
