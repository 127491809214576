import { FormInstance } from 'antd'
import { IFormReducerState } from '../../reducers/formReducer/formReducer.type'

export interface IFormContextState extends IFormReducerState {
  optionsMap: { [key: string]: any[] }
  setFormValuesMap: (formName: string, formValue: FormInstance) => any
}

export const initialState: IFormContextState = {
  optionsMap: {},
  formValuesMap: new Map(),
  setFormValuesMap: (formName: string, formValue: FormInstance) => {},
}
