import React from 'react'
import { Menu, Button } from 'antd'
import { EnumCookieKeys, EnumMenuTypes, EnumPageUrl } from '../../types/enums'
import { Link, navigateTo } from '../../tools/link'
import { Z_INDEX } from './../../types/constants/styles'
import styled from 'styled-components'
import { debounce } from 'lodash'
import { removeCookie } from 'tiny-cookie'

interface IMenuContentProps {
  current: EnumMenuTypes
  refreshPage: () => void
}

interface IMenuItem {
  label: string
  url: string
}

const CustomMenu = styled.span`
  display: flex;
`

const MenuContent = ({ current, refreshPage }: IMenuContentProps) => {
  const menuList: IMenuItem[] = [
    { label: EnumMenuTypes.OwnStoreList, url: EnumPageUrl.OwnStorePage },
    { label: EnumMenuTypes.WholesaleList, url: EnumPageUrl.WholesaleListPage },
    { label: EnumMenuTypes.SpecialOpTime, url: EnumPageUrl.SpecialOpTimePage },
    { label: EnumMenuTypes.CityWarehouse, url: EnumPageUrl.CityWarehousePage },
    { label: EnumMenuTypes.EmployeeList, url: EnumPageUrl.EmployeeListPage },
    { label: EnumMenuTypes.TemporaryClosedList, url: EnumPageUrl.TemporaryClosedListPage },
  ]

  const onClickItem = (key) => {
    if (key == current && typeof refreshPage == 'function') {
      refreshPage()
    }
  }

  const signOut = debounce(() => {
    removeCookie(EnumCookieKeys.AuthToken)
    window.localStorage.clear()
    navigateTo(EnumPageUrl.LoginPage)
  }, 300)

  return (
    <CustomMenu>
      <Menu
        selectedKeys={[current]}
        mode="horizontal"
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'sticky',
          left: 0,
          top: 0,
          zIndex: Z_INDEX.Z_100,
        }}
      >
        {menuList.map((item) => (
          <Menu.Item
            key={item.label}
            onClick={() => {
              onClickItem(item.label)
            }}
          >
            <Link to={item.url} internal>
              {item.label}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
      <Button style={{ marginLeft: 'auto' }} onClick={() => signOut()}>
        Sign out
      </Button>
    </CustomMenu>
  )
}

export default MenuContent
