import React, { useState, useImperativeHandle, forwardRef } from 'react'
import IconContent from './IconContent'
import { DownloadOutlined } from '@ant-design/icons'
import apiCollection from './../../api'
import { message, notification } from 'antd'
import FullScreenLoading from './FullScreenLoading'
import { EnumExcelType } from '../../types/enums'
import { makeRequest_timeout } from '../../api/config'
import { getBrowserType } from '../../tools/browserFingerprint'

interface IDownloadIconProp {
  fileType?: string
  saveName?: string
  fileUrl: string
}

const DownloadIcon = forwardRef(
  ({ fileType = null, saveName, fileUrl }: IDownloadIconProp, ref) => {
    const [isLoading, showLoading] = useState<boolean>(false)

    useImperativeHandle(ref, () => ({ downloadFile }))

    const downloadFile = async () => {
      showLoading(true)
      try {
        const result = await makeRequest_timeout(
          apiCollection.getFileIsBase64(fileUrl),
          60000
        )

        const bstr = atob(result.data.data)
        let l = bstr.length
        const u8Arr = new Uint8Array(l)
        while (l--) {
          u8Arr[l] = bstr.charCodeAt(l)
        }

        let blob = new Blob([u8Arr], {
          type:
            fileType ||
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        let url = window.URL.createObjectURL(blob)
        let ele = document.createElement('a')
        ele.style.display = 'none'

        ele.href = url
        ele.download = saveName || result.data.filename
        document.querySelectorAll('body')[0].appendChild(ele)
        ele.click()
        ele.remove()
      } catch (error) {
        const errorMsg = error?.message ?? ''
        message.error(errorMsg || '下载文件失败，请稍后再试')
      }
      showLoading(false)
    }

    return (
      <>
        <IconContent
          icon={DownloadOutlined}
          clickCallback={downloadFile}
          tips={'下载'}
        />
        {isLoading && <FullScreenLoading />}
      </>
    )
  }
)

export default DownloadIcon
