import { IEmployeeItem } from "../../api/apiModel/employee";
import { IAreaItem, IConfigItem } from "../../api/apiModel/publicApi";
import { EnumConfigType } from "../../types/enums";


export interface IStoreViewContextState {
    configMap: Map<EnumConfigType, IConfigItem[]>,
    areaList: IAreaItem[],
    employeeList: IEmployeeItem[]
}

export const initialState: IStoreViewContextState = {
    configMap: new Map(),
    areaList: [],
    employeeList: [],
}