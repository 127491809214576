import React from 'react'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { Z_INDEX } from '../../types/constants/styles'

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  background-color: white;
  z-index: ${Z_INDEX.Z_10};
`
interface IFullScreenLoadingProp {
  tip?: string
}

const FullScreenLoading = ({ tip }: IFullScreenLoadingProp) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />

  return (
    <>
      <Container>
        <Spin
          size="large"
          indicator={antIcon}
          tip={tip || '正在处理，请稍候...'}
        />
      </Container>
    </>
  )
}

export default FullScreenLoading
