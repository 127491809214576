import { Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface ICustomButton {
  as: any
  size: number
}

const CustomButton = styled.div<ICustomButton>`
  font-size: 2.4rem;
  cursor: pointer;
  font-size: ${(p) => p.size + 'rem' || '2rem'};
`

interface IIconProps {
  icon: any
  size?: number
  tips?: string
  clickCallback?: (props: any) => void
}

const IconContent = ({ size, icon, tips, clickCallback }: IIconProps) => {
  return (
    <Tooltip title={tips} placement="bottomLeft">
      <CustomButton as={icon} size={size || 2} onClick={clickCallback} />
    </Tooltip>
  )
}

export default IconContent
