import { formatStrParams, makeRequest } from './config'
import { IAddWholesaleRequest, IGetWholesaleListRequest, IGetWholesaleDetailRequest, IUpdateWholesaleRequest, IWholesaleItem } from './apiModel/wholesale'
import { IResponseDataModel } from './apiModel/publicApi';

const getWholesaleList = (query: IGetWholesaleListRequest) => makeRequest<IResponseDataModel<IWholesaleItem[]>>(`wholesale?${formatStrParams(query)}`, { method: 'GET' })

const getWholesaleDetail = ({ Store_Code }: IGetWholesaleDetailRequest) =>
    makeRequest(`wholesale/${Store_Code}`, {
        method: 'GET',
    });

const addWholesale = (query: IAddWholesaleRequest) =>
    makeRequest(`wholesale`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(query),
    });

const updateWholesale = (query: IUpdateWholesaleRequest) =>
    makeRequest(`wholesale/${query.Store_Code}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(query),
    });

export default {
    getWholesaleDetail, getWholesaleList, addWholesale, updateWholesale
}