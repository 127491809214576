import React, {
  createContext,
  useState,
  useContext,
  useReducer,
  useEffect,
} from 'react'
import storeViewReducer from '../../reducers/storeViewReducer/storeViewReducer'
import {
  IStoreViewReducerState,
  StoreViewActions,
} from '../../reducers/storeViewReducer/storeViewReducer.type'
import { initialState, IStoreViewContextState } from './StoreViewContext.type'
import apiCollection from './../../api'
import { IAreaItem, IConfigItem } from '../../api/apiModel/publicApi'
import { EnumConfigType, EnumPageUrl } from '../../types/enums'
import { IEmployeeItem } from '../../api/apiModel/employee'

interface IStoreViewProviderProps {
  children: React.ReactNode
}

export const StoreViewContext =
  createContext<IStoreViewContextState>(initialState)
export const useStoreViewContext = () => useContext(StoreViewContext)

export const StoreViewProvider = ({ children }: IStoreViewProviderProps) => {
  const [state, dispatch]: [
    IStoreViewReducerState,
    (a: StoreViewActions) => any
  ] = useReducer(storeViewReducer, initialState) as any
  const [configMap, setConfigMap] = useState<
    Map<EnumConfigType, IConfigItem[]>
  >(new Map())
  const [areaList, setAreaList] = useState<IAreaItem[]>()
  const [employeeList, setEmployeeList] = useState<IEmployeeItem[]>([])

  const initConfigList = async () => {
    const result = await apiCollection.getConfig()
    const list = result.data?.data ?? []
    const defaultMap = new Map()
    list.forEach((item) => {
      const mapItem = defaultMap.get(item.Type) ?? []
      defaultMap.set(item.Type, [...mapItem, item])
    })
    setConfigMap(defaultMap)
  }

  const getEmployeeList = async () => {
    if (
      window &&
      window.location.pathname.replace(/\//gi, '') ==
        EnumPageUrl.EmployeeListPage
    ) {
      return
    }

    const result = await apiCollection.getEmployeeList({})
    const list = (result.success ? result.data?.data : []).map((item) => ({
      ...item,
      id: item.Employee_No,
      value: item.Name_CN,
    }))
    setEmployeeList(list)
  }

  const initAreaList = async () => {
    const result = await apiCollection.getAreas({})
    const list = result.data?.data ?? []
    setAreaList(list)
  }

  useEffect(() => {
    initConfigList()
    initAreaList()
    getEmployeeList()
  }, [])

  return (
    <StoreViewContext.Provider
      value={{ ...state, configMap, areaList, employeeList }}
    >
      {children}
    </StoreViewContext.Provider>
  )
}
