import React, { useEffect, useState } from 'react'
import { TableProps } from 'antd/lib/table'
import { Table } from 'antd'
import FullScreenLoading from '../icon/FullScreenLoading'

interface ITableProps extends TableProps<any> {}

//antd会早于styled-component的处理，所以先隐藏
const TableContent = (props: ITableProps) => {
  const [isShowTable, showTable] = useState<boolean>(false)

  useEffect(() => {
    showTable(true)
  }, [])

  if (!isShowTable) {
    return <></>
  }

  return (
    <>
      <Table {...props} />
    </>
  )
}

export default TableContent
