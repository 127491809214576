import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { Form, Collapse, Modal, Spin, Button, Affix } from 'antd'
import { FormChangeInfo } from 'rc-field-form/lib/FormContext'
import { IFromMapItem } from '../../types/interface'
import FormPanel from './FormPanel'
import { EnumFormFieldType } from '../../types/enums'
import {
  FormProvider,
  useFormContext,
} from '../../context/formContext/FormContext'
import moment from 'moment'
import { debounce } from 'lodash'

const { Panel } = Collapse

type IOperationBoxProps = Pick<IFromMapItem, 'buttonPosition'>

const OperationBox = styled.div<IOperationBoxProps>`
  display: flex;
  justify-content: ${(p) => p.buttonPosition || 'center'};
  align-items: center;
  width: 100%;
  padding: 2.4rem 0;
`

const CancelButton = styled(Button)`
  margin-left: 2.4rem;
`

interface IFormContentProps {
  formMap: IFromMapItem[]
  hiddenPanel?: boolean
  formKeyName?: string
  optionsMap?: { [key: string]: any }
  isEdit?: boolean
  initData?: {}
  disableCatch?: boolean
  submitCallback: (formData: { [key: string]: any }) => void
  cancelCallback?: () => void
  expandIcon?: () => ReactNode
  onFormChange?: (name: string, info: FormChangeInfo) => void
}

const CustomPanelHeader = styled.span`
  width: 100%;
  display: inline-block;
  padding: 1.2rem 4rem;
  position: absolute;
  top: 0;
  left: 0;
`

const CustomPannel = styled(Panel)`
  position: relative;
`

const FormContent = ({
  formMap,
  optionsMap,
  isEdit,
  formKeyName = '',
  initData,
  submitCallback,
  cancelCallback,
  ...props
}: IFormContentProps) => {
  const DEBOUNCE_INTERVAL = 600
  const { formValuesMap } = useFormContext()
  const formatPathname =
    (typeof window !== 'undefined' &&
      window.location.pathname.replace(/(\/)?/gi, '').toUpperCase()) ||
    'OWNSTORE'
  const formationFormName = useRef('')
  const [panelActiveKey, setPanelActiveKey] = useState<string[]>([])
  const [isShowUseCatchTips, showUseCatchTips] = useState<boolean>(false)
  const [isLoading, showLoading] = useState<boolean>(false)

  const OutsidePanelButtonInfo = useMemo(
    () =>
      formMap?.find((item) => item.showSubmitButton || item.showCancelButton),
    [formMap]
  )

  const saveFormMap = () => {
    if (props?.disableCatch) return
    const formData = [...formValuesMap].reduce(
      (mixData, [_, value]) => ({
        ...mixData,
        ...(value?.getFieldsValue() ?? {}),
      }),
      {}
    )
    window.localStorage.setItem(
      formationFormName.current,
      JSON.stringify(formData)
    )
  }

  const combineData = (isMerge?: boolean): void => {
    const dataStr = window.localStorage.getItem(formationFormName.current)
    const catchData =
      dataStr && isMerge && !props.disableCatch ? JSON.parse(dataStr) : {}
    const formData = { ...initData, ...catchData }
    formMap.forEach((item) => {
      const formMapItem = formValuesMap.get(item.panelName)
      const fieldsList = item.fieldsList.map((fItem) => {
        let value = formData[fItem.name]
        if (value) {
          switch (fItem.type) {
            case EnumFormFieldType.DatePicker:
            case EnumFormFieldType.TimePicker:
              value = formData[fItem.name] ? moment(formData[fItem.name]) : null
              break

            case EnumFormFieldType.TimeRangePicker:
            case EnumFormFieldType.DateRangePicker:
              value = value?.map((item) => moment(item))
              break
          }
        }
        return {
          name: fItem.name,
          value,
        }
      })
      formMapItem.setFields(fieldsList)
    })
    showUseCatchTips(false)
  }

  const initFormData = () => {
    const varifyMapExist = formMap.find(
      (item) => !formValuesMap.get(item.panelName)
    )
    if (varifyMapExist || (isEdit && !Object.keys(initData ?? {}).length)) {
      return
    }
    const dataStr = window.localStorage.getItem(formationFormName.current)
    if ((!dataStr || dataStr === '{}') && !Object.keys(initData ?? {}).length) {
      return
    }
    if (dataStr && !props.disableCatch && !isEdit) {
      showUseCatchTips(true)
    } else {
      combineData()
    }
  }

  const onClickSubmit = useCallback(async () => {
    showLoading(true)
    let formatFormData = {}
    const invalidFieldKeys: string[] = []
    for (const item of formMap) {
      try {
        const values = formValuesMap.get(item.panelName)
        const formData = await values.validateFields()
        formatFormData = { ...formatFormData, ...formData }

        item.fieldsList.forEach((field) => {
          switch (field.type) {
            case EnumFormFieldType.DatePicker:
            case EnumFormFieldType.TimePicker:
              const unitString =
                field.type === EnumFormFieldType.DatePicker ? 'day' : 'minute'
              formatFormData[field.name] =
                formatFormData?.[field.name]?.startOf(unitString).unix() ?? null
              break

            case EnumFormFieldType.TimeRangePicker:
            case EnumFormFieldType.DateRangePicker:
              formatFormData[field.name] = formatFormData?.[field.name]?.reduce(
                (reducers, item, index) => {
                  const unitString =
                    field.type === EnumFormFieldType.DateRangePicker
                      ? 'day'
                      : 'minute'
                  const unixTimeMs = item
                    ? !index
                      ? item?.startOf(unitString).unix()
                      : item?.endOf(unitString).unix()
                    : null
                  return unixTimeMs ? [...reducers, unixTimeMs] : reducers
                },
                []
              )
              break
          }
        })
      } catch (error) {
        invalidFieldKeys.push(item.panelName)
      }
    }

    if (invalidFieldKeys.length) {
      setPanelActiveKey(invalidFieldKeys)
    } else {
      try {
        window.localStorage.removeItem(formationFormName.current)
        await submitCallback(formatFormData)
      } catch (error) {
        saveFormMap()
      }
    }
    showLoading(false)
  }, [formValuesMap, formMap])

  const onClickCancel = () => {
    cancelCallback()
  }

  const onClickCancelUseCatch = () => {
    window.localStorage.removeItem(formationFormName.current)
    showUseCatchTips(false)
    combineData()
  }

  const toggleDisplayPanel = (name: string, isHidden = false) => {
    if (isHidden) return
    const oldActives = [...panelActiveKey]
    const index = oldActives.indexOf(name)
    if (index !== -1) {
      oldActives.splice(index, 1)
    } else {
      oldActives.push(name)
    }
    setPanelActiveKey(oldActives)
  }

  const panelHeader = (panelName, hiddenPanel = false) => {
    return (
      <CustomPanelHeader
        onClick={() => {
          toggleDisplayPanel(panelName, hiddenPanel)
        }}
      >
        {hiddenPanel ? '' : panelName}
      </CustomPanelHeader>
    )
  }

  const catchByChangeFields = (formName: string, formInfo: FormChangeInfo) => {
    const { changedFields } = formInfo
    if (changedFields.length != 1) return
    if (!isEdit && !props.disableCatch) {
      saveFormMap()
    }

    if (!!props?.onFormChange && typeof props?.onFormChange == 'function') {
      props?.onFormChange(formName, formInfo)
    }
  }

  const onFieldsChange = debounce(catchByChangeFields, DEBOUNCE_INTERVAL)

  useEffect(() => {
    const keyName = isEdit ? formKeyName : 'default'
    formationFormName.current = `${formatPathname}_${keyName
      .replace(/\s+/gi, '_')
      .toUpperCase()}`
  }, [formKeyName, isEdit])

  useEffect(() => {
    initFormData()
  }, [formValuesMap, initData, props.disableCatch])

  useEffect(() => {
    if (!formMap.length) return
    const defaultActiveKey = formMap?.[0]?.panelName
    setPanelActiveKey([defaultActiveKey])
  }, [formMap])

  return (
    <Spin spinning={isLoading} delay={300}>
      <FormProvider optionsMap={optionsMap}>
        <Form.Provider onFormChange={onFieldsChange}>
          <Collapse
            activeKey={panelActiveKey}
            ghost={props.hiddenPanel}
            expandIcon={props.expandIcon}
          >
            {formMap.map((item, index) => (
              <CustomPannel
                header={panelHeader(item.panelName, props.hiddenPanel)}
                key={item.panelName}
                forceRender
              >
                <FormPanel
                  {...item}
                  onClickSubmit={onClickSubmit}
                  onClickCancel={onClickCancel}
                />
              </CustomPannel>
            ))}
          </Collapse>
          {!OutsidePanelButtonInfo?.panelName && (
            <OperationBox>
              <Button type="primary" onClick={onClickSubmit}>
                {'提交'}
              </Button>
              <CancelButton htmlType="button" onClick={onClickCancel}>
                {'取消'}
              </CancelButton>
            </OperationBox>
          )}
          <Modal
            visible={isShowUseCatchTips}
            onOk={() => {
              combineData(true)
            }}
            title={'温馨提示'}
            cancelText="放弃"
            okText="继续"
            onCancel={onClickCancelUseCatch}
          >
            <p>存在未完成的编辑，是否继续?</p>
          </Modal>
        </Form.Provider>
      </FormProvider>
    </Spin>
  )
}

export default FormContent
