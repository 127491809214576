import React, { useEffect, useState } from 'react'
import { EnumFormFieldType } from '../../types/enums'
import { IFormField } from '../../types/interface'
import {
  Select,
  Cascader,
  DatePicker,
  TimePicker,
  Input,
  FormInstance,
  Checkbox,
  Radio,
  AutoComplete,
} from 'antd'
import { useFormContext } from '../../context/formContext/FormContext'
import { CascaderValueType } from 'rc-cascader/lib/interface'
import moment from 'moment'

interface IFormFormFieldSelectProps {
  fieldInfo: IFormField
  form: FormInstance
  value?: any
  onChange?: (
    e:
      | CascaderValueType
      | React.ChangeEvent
      | [moment.Moment, moment.Moment]
      | any
  ) => void
}

const FormFieldSelectContent = ({
  fieldInfo,
  form,
  ...props
}: IFormFormFieldSelectProps) => {
  const formState = useFormContext()
  const { optionsMap } = formState
  const options =
    optionsMap?.[fieldInfo.optionsName] || fieldInfo?.extra?.options || []

  const [autoCompleteOptions, setAutoCompeteOptions] = useState<
    { value: string }[]
  >([])

  const customOnChange = (value) => {
    props.onChange(value || null)
    if (fieldInfo.onChange) {
      fieldInfo.onChange({ value, form, optionsMap })
    }
  }

  const autoCompleteSearch = (searchText: string) => {
    const matchOptions = options.filter((item) =>
      item.value?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    )
    setAutoCompeteOptions(matchOptions)
  }

  switch (fieldInfo.type) {
    case EnumFormFieldType.Select:
      return (
        <Select
          {...props}
          showSearch
          allowClear
          placeholder={fieldInfo.placeholder}
          defaultValue={fieldInfo.defaultValue}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={customOnChange}
          {...fieldInfo.extra}
        >
          {(optionsMap?.[fieldInfo.optionsName] ?? []).map((option, index) => (
            <Select.Option value={option?.id || option?.Id} key={index}>
              {option?.value || option?.Value}
            </Select.Option>
          ))}
        </Select>
      )

    case EnumFormFieldType.Cascader:
      return (
        <Cascader
          {...props}
          options={options}
          placeholder={fieldInfo.placeholder}
          onChange={customOnChange}
          {...fieldInfo.extra}
        />
      )

    case EnumFormFieldType.DatePicker:
      return (
        <DatePicker {...props} {...fieldInfo.extra} onChange={customOnChange} />
      )

    case EnumFormFieldType.DateRangePicker:
      return (
        <DatePicker.RangePicker
          {...props}
          {...fieldInfo.extra}
          ranges={{
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          onChange={customOnChange}
          onCalendarChange={(dates) => {
            if (dates?.filter((item) => !item)) {
              const todayTime = moment()
              const selectTime = dates.find((item) => item)
              if (todayTime.unix() > selectTime.unix()) {
                form.setFields([
                  {
                    name: fieldInfo.name,
                    value: [selectTime, todayTime],
                  },
                ])
              }
            }
          }}
        />
      )

    case EnumFormFieldType.Input:
      return (
        <Input
          {...props}
          {...fieldInfo.extra}
          placeholder={fieldInfo.placeholder || ''}
          onChange={customOnChange}
          allowClear
        />
      )

    case EnumFormFieldType.TimePicker:
      return (
        <TimePicker {...props} {...fieldInfo.extra} onChange={customOnChange} />
      )

    case EnumFormFieldType.TimeRangePicker:
      return (
        <TimePicker.RangePicker
          {...props}
          {...fieldInfo.extra}
          onChange={customOnChange}
        />
      )

    case EnumFormFieldType.Checkbox:
      return (
        <Checkbox.Group
          {...props}
          options={options}
          {...fieldInfo.extra}
          onChange={customOnChange}
        />
      )
    case EnumFormFieldType.Radio:
      return (
        <Radio.Group
          {...props}
          options={options}
          onChange={customOnChange}
          {...fieldInfo.extra}
        />
      )

    case EnumFormFieldType.AutoComplete:
      return (
        <AutoComplete
          {...props}
          onChange={customOnChange}
          options={autoCompleteOptions}
          onSearch={autoCompleteSearch}
          defaultOpen={false}
          allowClear
          {...fieldInfo.extra}
        />
      )
  }
}

export default FormFieldSelectContent
