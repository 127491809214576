import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Form, Row, Button, Col } from 'antd'
import { IFromMapItem } from '../../types/interface'
import FormFiledContent from './FormFieldContent'
import { useFormContext } from '../../context/formContext/FormContext'

type IOperationBoxProps = Pick<IFromMapItem, 'buttonPosition'>

const OperationBox = styled.div<IOperationBoxProps>`
  display: flex;
  justify-content: ${(p) => p.buttonPosition || 'center'};
  align-items: center;
  width: 100%;
`

const CustomButtonBox = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
`

const CustomButton = styled.div<IOperationBoxProps>`
  margin-right: 2.4rem;
  &:last-child {
    margin-right: 0;
  }
`

const CancelButton = styled(Button)`
  margin-left: 2.4rem;
`

interface IFormContentProps extends IFromMapItem {
  onClickSubmit: () => void
  onClickCancel: () => void
}

const FormPanel = ({
  panelName,
  fieldsList,
  showSubmitButton,
  submitButtonLabel,
  onClickSubmit,
  onClickCancel,
  ...props
}: IFormContentProps) => {
  const [form] = Form.useForm()
  const { setFormValuesMap } = useFormContext()

  useEffect(() => {
    setFormValuesMap(panelName, form)
  }, [])

  const onReset = () => {
    form.resetFields()
    onClickCancel()
  }

  return (
    <Form name={panelName} form={form} scrollToFirstError layout="horizontal">
      <Row>
        {fieldsList?.map((item) => (
          <FormFiledContent fieldInfo={item} form={form} key={item.name} />
        ))}

        <Col span={24}>
          <OperationBox buttonPosition={props.buttonPosition}>
            <CustomButtonBox>
              {props.customFormButton?.map((item, index) => (
                <CustomButton key={index}>{item}</CustomButton>
              ))}
            </CustomButtonBox>
            {(showSubmitButton || props.showCancelButton) && (
              <>
                <Button type="primary" onClick={onClickSubmit}>
                  {submitButtonLabel || '提交'}
                </Button>
                {props.showCancelButton && (
                  <CancelButton htmlType="button" onClick={onReset}>
                    {props.cancelButtonLabel || '取消'}
                  </CancelButton>
                )}
              </>
            )}
          </OperationBox>
        </Col>
      </Row>
    </Form>
  )
}

export default FormPanel
