import { IOwnStoreItem } from './apiModel/ownStore'
import {
  IAreaItem,
  IConfigItem,
  IGeocodingResponse,
  IGetAreaRequest,
  IGetGeocodingRequest,
  IResponseDataModel
} from './apiModel/publicApi'
import { formatStrParams, makeRequest } from './config'

const getConfig = () => {
  return makeRequest<IResponseDataModel<IConfigItem[]>>('config', { method: 'GET' })
}

const getAreas = (query: IGetAreaRequest) => {
  return makeRequest<IResponseDataModel<IAreaItem[]>>(`area`, { method: 'GET' })
}

const getFileIsBase64 = (url: string) => makeRequest<IResponseDataModel<string>>(url, { method: 'GET' })

const getGeocoding = (query: IGetGeocodingRequest) => {
  const paramsStr = formatStrParams(query)
  return makeRequest<IGeocodingResponse>(`geocoding?${paramsStr}`, { method: 'GET' })
}

export default {
  getConfig,
  getAreas,
  getFileIsBase64,
  getGeocoding
}
