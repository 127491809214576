

import { SET_FORM_VALUES_MAP } from '../../types/constants/formConstants';
import { combineReducer } from '../combineReducer';
import { formValuesState, setFormMapAction } from './formReducer.type';

const formValuesMap = (
    state = new Map() as formValuesState,
    action: setFormMapAction) => {
    if (action.type === SET_FORM_VALUES_MAP) {
        return state.set(action.payload.formName, action.payload.formValue)
    }
    return state;
}


export const formReducers = {
    formValuesMap
};

export default combineReducer(formReducers);
