import { formatStrParams, makeRequest } from './config'
import {
  IGetSpecialOpTimeListInfoRequestBody,
  IUpdateSpecialOpTimeDetailInfoRequestBody,
  ICreateSpecialOpTimeDetailInfoRequestBody,
} from './apiModel/specialOpTime'

const getSpecialOpTimeList = (query: IGetSpecialOpTimeListInfoRequestBody) => {
  const paramsStr = formatStrParams(query)
  return makeRequest(`specialOpTime?${paramsStr}`, { method: 'GET' })
}

const getSpecialOpTimeDetailInfo = (Id: number) => {
  return makeRequest(`specialOpTime/${Id}`, { method: 'GET' })
}

const updateSpecialOpTimeDetailInfo = (
  Id: number,
  body: IUpdateSpecialOpTimeDetailInfoRequestBody
) => {
  return makeRequest(`specialOpTime/${Id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

const createSpecialOpTimeDetailInfo = (
  body: ICreateSpecialOpTimeDetailInfoRequestBody
) => {
  return makeRequest('specialOpTime', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

export default {
  getSpecialOpTimeList,
  getSpecialOpTimeDetailInfo,
  updateSpecialOpTimeDetailInfo,
  createSpecialOpTimeDetailInfo,
}
