export const TINY = 576;
export const SMALL = 768;
export const MEDIUM = 992;
export const LARGE = 1600;

export const MEDIA_TINY = `@media (max-width: ${TINY}px)`;
export const MEDIA_MAX_SMALL = `@media (max-width: ${SMALL}px)`;
export const MEDIA_SMALL = `@media (min-width: ${TINY +
  1}px) and (max-width: ${SMALL}px)`; // Anything below or equal to SMALL
export const MEDIA_MIN_MEDIUM = `@media (min-width: ${SMALL + 1}px)`; // Anything above SMALL
export const MEDIA_MAX_MEDIUM = `@media (max-width: ${MEDIUM}px)`; // Anything below or equal to MEDIUM
export const MEDIA_MEDIUM = `@media (min-width: ${SMALL +
  1}px) and (max-width: ${MEDIUM}px)`; // Anything above SMALL and below or equal to MEDIUM
export const MEDIA_MIN_LARGE = `@media (min-width: ${MEDIUM + 1}px)`; // Anything above MEDIUM
export const MEDIA_MIN_X_LARGE = `@media (min-width: ${LARGE + 1}px)`; // Anything above LARGE

// Our most common mobile sizes, used for setting the correct image size
export const VW_320 = '(width: 320px)';
export const VW_360 = '(width: 360px)';
export const VW_375 = '(width: 375px)';
export const VW_414 = '(width: 414px)';

export const DEFAULT_DARK_BLUE_COLOR = 'rgba(0, 14, 34, 0.9)';

export const MOMENTS_FONT = '"DWMoments", "DWFutura", serif';

export const Z_INDEX = {
  Z_10001: 10001,
  Z_10000: 10000,
  Z_1001: 1001,
  Z_1000: 1000,
  Z_500: 500,
  Z_100: 100,
  Z_31: 31,
  Z_30: 30,
  Z_29: 29,
  Z_28: 28,
  Z_11: 11,
  Z_10: 10,
  Z_9: 9,
  Z_3: 3,
  Z_2: 2,
  Z_1: 1,
  Z_0: 0,
};
