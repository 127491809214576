import { formatStrParams, makeRequest } from './config'
import {
  IGetEmployeeListRequest,
  IUpdateEmployeeDetailInfoRequestBody,
  ICreateEmployeeDetailInfoRequestBody,
  IEmployeeItem,
} from './apiModel/employee'
import { IResponseDataModel } from './apiModel/publicApi'

const getEmployeeList = (query: IGetEmployeeListRequest) => {
  const paramsStr = formatStrParams(query)
  return makeRequest<IResponseDataModel<IEmployeeItem[]>>(`employee?${paramsStr}`, { method: 'GET' })
}

const getEmployeeDetailInfo = (Employee_No: string) => {
  return makeRequest(`employee/${Employee_No}`, { method: 'GET' })
}

const updateEmployeeDetailInfo = (
  Employee_No: string,
  body: IUpdateEmployeeDetailInfoRequestBody
) => {
  return makeRequest(`employee/${Employee_No}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

const createEmployeeDetailInfo = (
  body: ICreateEmployeeDetailInfoRequestBody
) => {
  return makeRequest('employee', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

export default {
  getEmployeeList,
  getEmployeeDetailInfo,
  updateEmployeeDetailInfo,
  createEmployeeDetailInfo,
}
