import React from 'react'
import styled from 'styled-components'
import {
  MEDIA_MIN_X_LARGE,
  MEDIA_MAX_SMALL,
  MEDIA_SMALL,
} from '../../types/constants/styles'

interface BodyContainerProps {
  children: React.ReactNode
}

const Container = styled.div`
  max-width: 1140px;
  margin: 0 auto;

  ${MEDIA_MAX_SMALL} {
    padding: 0;
  }

  ${MEDIA_SMALL} {
    padding: 0;
    max-width: 100vw;
  }

  ${MEDIA_MIN_X_LARGE} {
    max-width: 1300px;
  }
`

const BodyContainer = ({ children }: BodyContainerProps) => {
  return <Container>{children}</Container>
}

export default BodyContainer
