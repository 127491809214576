import { formatStrParams, makeRequest } from './config'
import {
  IAddTemporaryClosedRequest,
  IGetTemporaryClosedDetailRequest,
  IGetTemporaryClosedRequest,
  ITemporaryClosedItem,
  IUpdateTemporaryClosedRequest,
} from './apiModel/temporaryClosed'
import { IResponseDataModel } from './apiModel/publicApi'

const getTemporaryClosedList = (query: IGetTemporaryClosedRequest) => makeRequest<IResponseDataModel<ITemporaryClosedItem[]>>(
  `temporaryClosed?${formatStrParams(query)}`, { method: 'GET' })

const getTemporaryClosedDetail = ({ Id }: IGetTemporaryClosedDetailRequest) =>
  makeRequest(`temporaryClosed/${Id}`, {
    method: 'GET',
  })

const addTemporaryClosed = (query: IAddTemporaryClosedRequest) =>
  makeRequest(`temporaryClosed`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  })

const updateTemporaryClosed = ({ Id, ...query }: IUpdateTemporaryClosedRequest) =>
  makeRequest(`temporaryClosed/${Id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  })

export default {
  getTemporaryClosedList,
  getTemporaryClosedDetail,
  addTemporaryClosed,
  updateTemporaryClosed,
}
