import publicApi from './publicApi'
import employee from './employee'
import wholesale from './wholesale'
import ownStore from './ownStore'
import specialOpTime from './specialOpTime'
import cityWarehouse from './cityWarehouse'
import temporaryClosed from './temporaryClosed'

export default {
  ...publicApi,
  ...employee,
  ...wholesale,
  ...specialOpTime,
  ...ownStore,
  ...cityWarehouse,
  ...temporaryClosed
}
