import React from 'react'
import { IFormField } from '../../types/interface'
import { Form, Col, FormInstance } from 'antd'
import FormFieldSelectContent from './FormFieldSelect'

interface IFormFiledProps {
  fieldInfo: IFormField
  form: FormInstance
}

const FormFiledContent = ({ fieldInfo, form }: IFormFiledProps) => {
  const labelCol =
    !fieldInfo.col || fieldInfo.col == 24 ? 4 : fieldInfo.col == 12 ? 8 : 12

  return (
    <Col span={fieldInfo.col || 24} key={fieldInfo.name}>
      <Form.Item
        name={fieldInfo.name}
        label={fieldInfo.label}
        labelCol={{ span: labelCol }}
        getValueFromEvent={fieldInfo.getValueFromEvent}
        rules={[
          {
            required: fieldInfo.require,
            message: `${fieldInfo.label} 为必填项！`,
          },
          ...(fieldInfo?.rules ?? []),
        ]}
      >
        <FormFieldSelectContent fieldInfo={fieldInfo} form={form} />
      </Form.Item>
    </Col>
  )
}

export default FormFiledContent
