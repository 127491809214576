import { FormInstance } from 'antd'
import React, { createContext, useContext, useReducer } from 'react'
import formReducer from '../../reducers/formReducer/formReducer'
import {
  FormActions,
  IFormReducerState,
} from '../../reducers/formReducer/formReducer.type'
import { SET_FORM_VALUES_MAP } from '../../types/constants/formConstants'
import { initialState, IFormContextState } from './FormContext.type'

interface IFormProviderProps {
  children: React.ReactNode
  optionsMap: { [key: string]: any[] }
}

export const FormContext = createContext<IFormContextState>(initialState)
export const useFormContext = () => useContext(FormContext)

export const FormProvider = ({ children, optionsMap }: IFormProviderProps) => {
  const [state, dispatch]: [IFormReducerState, (a: FormActions) => any] =
    useReducer(formReducer, initialState) as any

  const setFormValuesMap = (formName: string, formValue: FormInstance) => {
    dispatch({
      type: SET_FORM_VALUES_MAP,
      payload: { formName, formValue },
    })
  }

  return (
    <FormContext.Provider value={{ ...state, optionsMap, setFormValuesMap }}>
      {children}
    </FormContext.Provider>
  )
}
