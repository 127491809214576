import { formatStrParams, makeRequest } from './config'
import { IAddOwnStoreRequest, IGetOwnStoreListRequest, IGetOwnStoreDetailRequest, IUpdateOwnStoreRequest, IOwnStoreItem, ICityTierItem, IUpdateDateByExcel } from './apiModel/ownStore'
import { IResponseDataModel } from './apiModel/publicApi';

const getOwnStoreList = (query: IGetOwnStoreListRequest) => makeRequest<IResponseDataModel<IOwnStoreItem[]>>(`ownStore?${formatStrParams(query)}`, { method: 'GET' })

const getOwnStoreDetail = ({ Store_Code }: IGetOwnStoreDetailRequest) =>
    makeRequest(`ownStore/${Store_Code}`, {
        method: 'GET',
    });

const addOwnStore = (query: IAddOwnStoreRequest) =>
    makeRequest(`ownStore`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(query),
    });

const updateOwnStore = (query: IUpdateOwnStoreRequest) =>
    makeRequest(`ownStore/${query.Store_Code}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(query),
    });

const getCityTierList = () => makeRequest<IResponseDataModel<ICityTierItem[]>>(`cityTier`, { method: 'GET' })

const uploadDataByExcel = ({ url, data }: IUpdateDateByExcel) => makeRequest(url, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
});

export default {
    getOwnStoreDetail, getOwnStoreList, addOwnStore, updateOwnStore, getCityTierList, uploadDataByExcel
}